import { FC } from 'react'
import styled from 'styled-components'

import { HighlightedText } from '../../../styles/Global'

interface RightAnswerProps {
  correctAnswers: string[]
  choices: { content: string; id: string }[]
}

const RightAnswerContainer = styled.p`
  font-size: 1.125rem;
  font-weight: 400;
  color: rgb(246 165 9);
  margin-top: 15px;
  line-height: 1.2;
  @media (max-width: 768px) {
    font-size: 0.75rem;
  }
`

const RightAnswer: FC<RightAnswerProps> = ({ correctAnswers, choices }) => {
  //@ts-ignore
  let answersLabels = correctAnswers.map((a) => choices?.find((c) => c.id === a).content)
  return (
    <RightAnswerContainer>
      {`Right ${correctAnswers.length < 2 ? 'Answer' : 'Answers'}: `}
      {answersLabels.map((item: string, index: number) => {
        const contents = choices.map((c) => c.content)
        const label = String.fromCharCode(65 + contents.indexOf(item))

        return (
          <HighlightedText key={index} themeText>
            {`${label} (${item})${index !== correctAnswers.length - 1 ? ', ' : ''}`}
          </HighlightedText>
        )
      })}
    </RightAnswerContainer>
  )
}

export default RightAnswer
