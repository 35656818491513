import React from 'react'

const Loader = ({ color }: any) => {
  return (
    <div className="flex items-center justify-center ">
      <div
        className={`w-7 h-7 border-4 border-t-4 border-${
          color || 'white'
        } border-t-transparent border-solid rounded-full animate-spin`}
      ></div>
    </div>
  )
}

export default Loader
