import React, { useState } from 'react'
import Select from 'react-select'

const customStyles = (error: any, isFocused: any) => ({
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: 'transparent',
    borderBottom: `2px solid ${error ? '#b91c1c' : isFocused ? '#fff' : '#d1d5db'}`, // error: red-700, focus: indigo-500, default: gray-300
    borderRadius: 0,
    borderColor: 'transparent', // Remove the full border
    boxShadow: 'none',
    '&:hover': {
      borderBottom: `2px solid ${error ? '#b91c1c' : isFocused ? '#fff' : '#d1d5db'}`,
    },
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#fff', // gray-500 for placeholder
    fontSize: '0.875rem', // Text-sm
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: '0', // Remove extra padding
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#fff', // gray-900 for text
    fontSize: '0.875rem', // Text-sm
  }),
  input: (provided: any) => ({
    ...provided,
    margin: 0,
    padding: 0,
  }),
  indicatorSeparator: () => ({
    display: 'none', // Remove separator
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    color: state.isFocused ? '#fff' : '#6b7280', // indigo-500 on focus, gray-500 normally
    '&:hover': {
      color: '#fff', // indigo-500 on hover
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: 10, // Ensure dropdown is above other elements
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused ? 'rgb(14, 56, 94)' : 'white', // Focus: indigo-500
    color: state.isFocused ? '#fff' : 'rgb(14, 56, 94)', // Text color: white on focus
    padding: '0.5rem 1rem', // Similar padding to text input
  }),
})

const InputSelect = ({ options, value, label, name, onChange, error }: any) => {
  const [isFocused, setIsFocused] = useState(false)

  const handleFocus = () => setIsFocused(true)
  const handleBlur = () => setIsFocused(false)

  return (
    <div className="relative z-0 mb-6 group flex flex-col items-start justify-center w-full">
      <Select
        options={options}
        value={value}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        styles={customStyles(!!error, isFocused || !!value)}
        className="w-full"
        name={name}
        placeholder=""
      />
      <label
        htmlFor={name}
        className={`absolute text-sm text-white duration-300 transform ${
          isFocused || !!value
            ? 'scale-75 -translate-y-6' // When focused or has value, float label
            : 'scale-100 translate-y-0' // Default state
        } top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-white peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 ${
          error ? '!text-red-700' : ''
        }`}
      >
        {label}
        <span className="text-red-700">*</span>
      </label>
      {!!error && <p className="text-red-700 text-sm">{error}</p>}
    </div>
  )
}

export default InputSelect
