import { FC, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import { AppLogo, Refresh } from '../../config/icons'
import { useQuiz } from '../../context/QuizContext'
import { device } from '../../styles/BreakPoints'
import { Flex, LogoContainer, ResizableBox } from '../../styles/Global'
import { formatQuestion, headers, refreshPage } from '../../utils/helpers'

import Button from '../ui/Button'
import CodeSnippet from '../ui/CodeSnippet'
import QuizImage from '../ui/QuizImage'
import ResultOverview from './ResultOverview'
import RightAnswer from './RightAnswer'
import axios from 'axios'
import toast from 'react-hot-toast'
import Loader from '../ui/Loader'

const ResultScreenContainer = styled.div`
  max-width: 900px;
  margin: 60px auto;
  @media ${device.md} {
    width: 90%;
    margin: 30px auto;
    padding-top: 40px;
  }
`

const InnerContainer = styled.div`
  background: ${({ theme }) => theme.colors.cardBackground};
  border-radius: 4px;
  margin: 0 auto;
  margin-bottom: 40px;
  padding: 40px 90px 90px 90px;
  @media ${device.md} {
    padding: 15px;
  }
`

const QuestionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  @media ${device.md} {
    flex-direction: column;
  }
`

const QuestionNumber = styled.h6`
  font-size: clamp(16px, 5vw, 24px);
  font-weight: 500;
  line-height: 1.3;
  color: ${({ theme }) => theme.colors.primaryText};
`

const QuestionStyle = styled.span`
  font-size: clamp(16px, 5vw, 24px);
  font-weight: 500;
  line-height: 1.3;
  color: ${({ theme }) => theme.colors.primaryText};
  margin-bottom: 20px;
  @media ${device.md} {
    margin-bottom: 10px;
  }
`

interface AnswerProps {
  correct?: boolean
  wrong?: boolean
}

const Answer = styled.li<AnswerProps>`
  border: 1px solid ${({ theme }) => theme.colors.border};
  width: 90%;
  @media ${device.md} {
    width: 100%;
  }
  background: ${({ theme }) => theme.colors.answerBg};
  border-radius: 16px;
  font-size: clamp(16px, 5vw, 18px);
  font-weight: 600;
  padding: 15px;
  color: ${({ theme }) => theme.colors.secondaryText};
  margin-top: clamp(13px, calc(10px + 6 * ((100vw - 600px) / 1320)), 16px);

  // if user answer matches to correct answer make answer background success color otherwise danger color
  ${({ correct }) =>
    correct &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.success};
      background-color: ${({ theme }) => theme.colors.successLight};
    `}

  ${({ wrong }) =>
    wrong &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.danger};
      background-color: ${({ theme }) => theme.colors.dangerLight};
    `}

  span {
    margin-right: 14px;
  }

  @media ${device.md} {
    font-weight: 400;
  }
`

const Score = styled.span<{ right: boolean }>`
  font-weight: 500;
  font-size: 1rem;
  color: rgb(246 165 9);
  margin-top: 4px;
  @media ${device.md} {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    margin-right: 10px;
  }
`

const ResultScreen: FC = () => {
  const { result, quizDetails, setQuizDetails, setResult } = useQuiz()
  const [loadingResults, setLoadingResults] = useState(true)
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/api/Exam/get`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${localStorage.getItem('U_TOKEN')}`,
        },
      })
      .then((res) => {
        let results: any = []
        res?.data?.questions?.map((q: any) => {
          results.push({
            ...formatQuestion(q),
            selectedAnswer: [
              q.answers?.find((a: any) => a.id === q.userAnswer)?.id || '',
            ],
            correctAnswers: [q.answers?.find((a: any) => a.correct)?.id || ''],
            score: q?.answerIsCorrect ? q.score : 0,
          })
        })
        setQuizDetails({
          ...quizDetails,
          obtainedScore: res.data.exam.totalScore,
          totalQuestionAttempted: res.data.questions.filter((q: any) => !!q.userAnswer)
            ?.length,
          totalQuestions: res.data.questions?.length,
          date: res.data.exam?.examDate,
        })
        setResult(results)
        setLoadingResults(false)
      })
      .catch((err) => {
        setLoadingResults(false)
        toast.error('An error occurred while trying to retrieve your exam results.')
      })
  }, [])

  const onClickRetry = () => {
    refreshPage()
  }

  return (
    <ResultScreenContainer>
      <LogoContainer></LogoContainer>
      <InnerContainer>
        <div className="flex items-center justify-center gap-3 mb-8">
          <img
            src="/images/u-logo.png"
            alt="Unihance"
            width="200px"
            height="125px"
            style={{ transition: 'width 1s' }}
          />
        </div>
        {loadingResults && <Loader />}
        {!loadingResults && (
          <>
            <ResultOverview result={result} />
            {console.log('result', { result })}
            {result.map(
              (
                {
                  question,
                  choices,
                  code,
                  image,
                  correctAnswers,
                  selectedAnswer,
                  score,
                  isMatch,
                },
                index: number
              ) => {
                return (
                  <QuestionContainer key={`question_${question}_${index}`}>
                    <ResizableBox width="90%">
                      <Flex gap="4px">
                        <QuestionNumber>{`${index + 1}. `}</QuestionNumber>
                        <QuestionStyle>{question}</QuestionStyle>
                      </Flex>
                      <div>
                        {code && <CodeSnippet code={code} language="javascript" />}
                        {image && <QuizImage image={image} />}
                        <ul>
                          {choices.map(
                            (
                              { content, id }: { content: string; id: string },
                              index: number
                            ) => {
                              // Convert index to alphabet character
                              const label = String.fromCharCode(65 + index)
                              const correct =
                                selectedAnswer.includes(id) && correctAnswers.includes(id)
                              const wrong =
                                selectedAnswer.includes(id) &&
                                !correctAnswers.includes(id)

                              return (
                                <Answer
                                  key={`answer_${content}_${index}`}
                                  correct={correct}
                                  wrong={wrong}
                                >
                                  <span>{label}.</span>
                                  {content}
                                </Answer>
                              )
                            }
                          )}
                        </ul>
                        {/* only show if the answer is wrong */}
                        {!isMatch && (
                          <RightAnswer
                            correctAnswers={correctAnswers}
                            choices={choices}
                          />
                        )}
                      </div>
                    </ResizableBox>
                    <Score right={isMatch}>{`Score ${score}`}</Score>
                  </QuestionContainer>
                )
              }
            )}
          </>
        )}
      </InnerContainer>
      {/* <Flex flxEnd>
        <Button
          text="RETRY"
          onClick={onClickRetry}
          icon={<Refresh />}
          iconPosition="left"
          bold
        />
      </Flex> */}
    </ResultScreenContainer>
  )
}

export default ResultScreen
