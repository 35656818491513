import styled from 'styled-components'

import { AppLogo } from '../../config/icons'
import { useQuiz } from '../../context/QuizContext'
import { quizTopics } from '../../data/quizTopics'
import { device } from '../../styles/BreakPoints'
import {
  CenterCardContainer,
  HighlightedText,
  LogoContainer,
  PageCenter,
} from '../../styles/Global'
import { ScreenTypes } from '../../types'

import Button from '../ui/Button'
import Input from '../ui/Input'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { formatQuestion, headers } from '../../utils/helpers'
import toast from 'react-hot-toast'
import Loader from '../ui/Loader'
import Select, { ActionMeta } from 'react-select'
import InputSelect from '../ui/InputSelect'
import ReactSelect from '../ui/ReactSelect'
import OTPInput from 'react-otp-input'

const Heading = styled.h2`
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`

const DetailText = styled.p`
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 29px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 0.75rem;
  }
`

const SelectButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 60%;
  gap: 30px;
  margin-top: 40px;
  margin-bottom: 45px;
  @media ${device.md} {
    row-gap: 20px;
    column-gap: 20px;
    max-width: 100%;
  }
`

interface SelectButtonProps {
  active: boolean
  disabled?: boolean
}

const SelectButton = styled.div<SelectButtonProps>`
  background-color: ${({ disabled, theme }) =>
    disabled ? `${theme.colors.disabledCard}` : `${theme.colors.selectTopicBg}`};
  border: ${({ active, theme }) =>
    active
      ? `2px solid ${theme.colors.themeColor}`
      : `1px solid ${theme.colors.disabledButton}`};
  transition: background-color 0.4s ease-out;
  border-radius: 10px;
  padding: 14px 10px;
  display: flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  @media ${device.md} {
    padding: 10px;
    tap-highlight-color: transparent;
    -webkit-tap-highlight-color: transparent;
  }
`

const SelectButtonText = styled.span`
  font-size: 1.125rem;
  font-weight: 600;
  margin-left: 10px;
  @media (max-width: 768px) {
    font-size: 1rem;
    font-weight: 500;
  }
`
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
const isValidEmail = (email: string) => emailRegex.test(email)

const jordanGovernorates = [
  { label: 'Amman', value: 'amman' },
  { label: 'Irbid', value: 'irbid' },
  { label: 'Zarqa', value: 'zarqa' },
  { label: 'Aqaba', value: 'aqaba' },
  { label: 'Mafraq', value: 'mafraq' },
  { label: 'Jerash', value: 'jerash' },
  { label: 'Madaba', value: 'madaba' },
  { label: 'Balqa', value: 'balqa' },
  { label: 'Ajloun', value: 'ajloun' },
  { label: 'Karak', value: 'karak' },
  { label: 'Tafilah', value: 'tafilah' },
  { label: "Ma'an", value: 'maan' },
  { label: 'Salt', value: 'salt' },
]

const jordanUniversityMajors = [
  { label: 'Medicine', value: 'medicine' },
  { label: 'Dentistry', value: 'dentistry' },
  { label: 'Pharmacy', value: 'pharmacy' },
  { label: 'Engineering', value: 'engineering' },
  { label: 'Computer Science', value: 'computer-science' },
  { label: 'Information Technology', value: 'information-technology' },
  { label: 'Business Administration', value: 'business-administration' },
  { label: 'Accounting', value: 'accounting' },
  { label: 'Law', value: 'law' },
  { label: 'Nursing', value: 'nursing' },
  { label: 'Architecture', value: 'architecture' },
  { label: 'Economics', value: 'economics' },
  { label: 'Marketing', value: 'marketing' },
  { label: 'Public Administration', value: 'public-administration' },
  { label: 'Civil Engineering', value: 'civil-engineering' },
  { label: 'Electrical Engineering', value: 'electrical-engineering' },
  { label: 'Mechanical Engineering', value: 'mechanical-engineering' },
  { label: 'Chemical Engineering', value: 'chemical-engineering' },
  { label: 'Physics', value: 'physics' },
  { label: 'Chemistry', value: 'chemistry' },
  { label: 'Mathematics', value: 'mathematics' },
  { label: 'Biology', value: 'biology' },
  { label: 'Environmental Science', value: 'environmental-science' },
  { label: 'Education', value: 'education' },
  { label: 'Journalism', value: 'journalism' },
  { label: 'Media', value: 'media' },
  { label: 'Political Science', value: 'political-science' },
  { label: 'International Relations', value: 'international-relations' },
  { label: 'History', value: 'history' },
  { label: 'Sociology', value: 'sociology' },
  { label: 'Anthropology', value: 'anthropology' },
  { label: 'Psychology', value: 'psychology' },
  { label: 'Fine Arts', value: 'fine-arts' },
  { label: 'Theater', value: 'theater' },
  { label: 'Graphic Design', value: 'graphic-design' },
  { label: 'Interior Design', value: 'interior-design' },
  { label: 'Islamic Studies', value: 'islamic-studies' },
  { label: 'Arabic Language and Literature', value: 'arabic-language-literature' },
  { label: 'English Language and Literature', value: 'english-language-literature' },
  { label: 'French Language', value: 'french-language' },
  { label: 'Translation', value: 'translation' },
  { label: 'Tourism and Hospitality', value: 'tourism-hospitality' },
  { label: 'Agriculture', value: 'agriculture' },
]

const QuizTopicsScreen: React.FC = () => {
  const { setQuestions, setCurrentScreen, setExamId } = useQuiz()
  const [formData, setFormData] = useState<any>({
    phone: '+9627',
  })
  const [errors, setErrors] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [showOTP, setShowOTP] = useState<boolean>(false)
  const [otp, setOTP] = useState<string>('')
  const [userId, setUserId] = useState<string>('')
  const [timeLeft, setTimeLeft] = useState(0)

  useEffect(() => {
    // Exit if no time left
    if (timeLeft <= 0) return

    // Set interval to count down every second
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1)
    }, 1000)

    // Clean up the interval on component unmount or when timeLeft changes
    return () => clearInterval(intervalId)
  }, [timeLeft])

  const formatTime = (seconds: any) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds
      .toString()
      .padStart(2, '0')}`
  }

  const goToQuizDetailsScreen = () => {
    setCurrentScreen(ScreenTypes.QuizDetailsScreen)
  }

  const goToResultScreen = () => {
    setCurrentScreen(ScreenTypes.ResultScreen)
  }

  const handleChange = (e: any) => {
    let val = e.target.value
    let name = e.target.name
    if (name === 'phone' && !val?.trim()) {
      val = '+9627'
    }
    if (name === 'phone' && isNaN(val)) {
      return
    }
    if (name === 'phone' && !val.startsWith('+9627')) {
      if (val?.trim() === '+962' || val?.trim() === '+9627') {
        val = '+9627'
      } else {
        val = '+9627' + val?.trim()
      }
    }
    if (name === 'phone' && val?.length > 13) {
      return
    }
    if (!!errors?.[name]) {
      setErrors({ ...errors, [name]: null })
    }
    setFormData({
      ...formData,
      [name]: val,
    })
  }

  const handleSelectChange = (
    newValue: any,
    actionMeta: ActionMeta<any>,
    name: string
  ) => {
    if (!!errors?.[name]) {
      setErrors({ ...errors, [name]: null })
    }
    setFormData({
      ...formData,
      [name]: newValue?.value || null,
    })
  }

  const submitUser = () => {
    setErrors({})
    let newErrors: any = {}
    if (!formData.name?.trim()) {
      newErrors['name'] = 'This field is required!'
    }
    if (!formData.email?.trim()) {
      newErrors['email'] = 'This field is required!'
    }
    if (!formData.phone?.trim() || formData.phone?.trim() === '+9627') {
      newErrors['phone'] = 'This field is required!'
    }
    if (formData.phone?.trim()?.length < 13) {
      newErrors['phone'] = 'Phone number is invalid!'
    }
    if (!formData.email?.trim() && !isValidEmail(formData.email?.trim())) {
      newErrors['email'] = 'Email is invalid!'
    }
    if (!formData.gender) {
      newErrors['gender'] = 'This field is required!'
    }
    if (!formData.governorate) {
      newErrors['governorate'] = 'This field is required!'
    }
    if (!formData.major) {
      newErrors['major'] = 'This field is required!'
    }
    setErrors(newErrors)
    if (Object.values(newErrors)?.length) {
      return
    }

    const dataForm = new FormData()
    dataForm.append('name', formData.name)
    dataForm.append('email', formData.email)
    dataForm.append('phone', formData.phone)
    dataForm.append('major', formData.major)
    dataForm.append('governorate', formData.governorate)
    dataForm.append('gender', formData.gender)
    setLoading(true)
    axios
      .post(`${process.env.REACT_APP_API}/api/Exam/user/new`, dataForm, {
        headers: headers,
      })
      .then((res) => {
        if (res.data?.success) {
          toast.success(res.data.message)
          setUserId(res.data.userId)
          setShowOTP(true)
          setTimeLeft(120)
        } else {
          toast.error(res.data.message)
        }
        setLoading(false)
      })
      .catch((err) => {
        if (typeof err?.response?.data === 'string') {
          toast.error(err?.response?.data)
        } else {
          toast.error(err.message)
        }
        console.log('error', { err })
        setLoading(false)
      })
  }

  const verifyOTP = () => {
    if (!otp || isNaN(Number(otp)) || otp?.length < 4) {
      toast.error('Please enter the otp sent to your phone.')
      return
    }
    const otpForm = new FormData()
    otpForm.append('otp', otp)
    otpForm.append('userId', userId)
    setLoading(true)
    axios
      .post(`${process.env.REACT_APP_API}/api/Exam/user/otp/confirm`, otpForm, {
        headers: headers,
      })
      .then((res) => {
        if (!res.data.token) {
          toast.error('Login failed!')
          return
        }
        localStorage.setItem('U_TOKEN', res.data.token)
        axios
          .get(`${process.env.REACT_APP_API}/api/Exam/start?id=${res.data.id}`, {
            headers: {
              ...headers,
              Authorization: `Bearer ${localStorage.getItem('U_TOKEN')}`,
            },
          })
          .then((res) => {
            setExamId(res?.data?.examId)
            if (res?.data?.userExam?.totalScore) {
              goToResultScreen()
              setLoading(false)
            } else {
              setQuestions(res.data.questions?.map((q: any) => formatQuestion(q)))
              setTimeout(() => {
                goToQuizDetailsScreen()
                setLoading(false)
              }, 1000)
            }

            console.log('res', { res })
          })
          .catch((err) => {
            if (err.status === 409) {
              toast.error('The user provided is already registered.')
            } else {
              toast.error(err.message)
            }
            console.log('error', { err })
            setLoading(false)
          })
      })
      .catch((err) => {
        if (err.status === 409) {
          toast.error('The user provided is already registered.')
        } else {
          toast.error(err.message)
        }
        console.log('error', { err })
        setLoading(false)
      })
  }

  return (
    <PageCenter light justifyCenter>
      <CenterCardContainer>
        <LogoContainer>
          {/* <AppLogo /> */}
          <img
            src="/images/u-logo.png"
            alt="Unihance"
            width="350px"
            height="250px"
            style={{ transition: 'width 1s' }}
          />
        </LogoContainer>
        <Heading>
          Welcome To <HighlightedText> Unihance Assessment</HighlightedText>
        </Heading>
        <DetailText>
          {showOTP
            ? 'Please enter the OTP sent to you phone to verify your identity.'
            : 'Enter your details to start the assessment.'}{' '}
        </DetailText>
        {!showOTP && (
          <div className="flex flex-col items-center justify-start gap-4 mt-10 w-full px-10">
            <div className="w-full flex-wrap grid grid-cols-1 md:grid-cols-2 gap-4">
              <Input
                label={'Full Name'}
                value={formData?.name || ''}
                onChange={handleChange}
                name="name"
                error={errors?.name}
              />
              <Input
                label={'Email'}
                value={formData?.email || ''}
                onChange={handleChange}
                name="email"
                error={errors?.email}
              />
            </div>
            <div className="w-full flex-wrap grid grid-cols-1 md:grid-cols-2 gap-4">
              <Input
                label={'Phone Number'}
                value={formData?.phone || ''}
                onChange={handleChange}
                name="phone"
                error={errors?.phone}
              />
              <InputSelect
                options={[
                  { value: null, label: 'Select Option' },
                  { value: 1, label: 'Male' },
                  { value: 2, label: 'Female' },
                ]}
                label={'Gender'}
                value={formData?.gender || ''}
                onChange={handleChange}
                name="gender"
                error={errors?.gender}
              />
            </div>
            <div className="w-full flex-wrap grid grid-cols-1 md:grid-cols-2 gap-4">
              <ReactSelect
                options={jordanGovernorates}
                value={
                  !!formData?.governorate
                    ? jordanGovernorates?.find((g) => g.value === formData?.governorate)
                    : null
                }
                onChange={(val: any, _: any) => handleSelectChange(val, _, 'governorate')}
                error={errors?.governorate}
                label="Governorate"
                name="governorate"
              />
              <ReactSelect
                options={jordanUniversityMajors}
                value={
                  !!formData?.major
                    ? jordanUniversityMajors?.find((g) => g.value === formData?.major)
                    : null
                }
                onChange={(val: any, _: any) => handleSelectChange(val, _, 'major')}
                error={errors?.major}
                label="University Major"
                name="major"
              />
            </div>
          </div>
        )}

        {showOTP && (
          <div className="flex flex-col items-center justify-start gap-4 mt-4 w-full px-10">
            <div className="w-full flex items-center justify-center gap-4">
              {/* @ts-ignore */}
              <OTPInput
                value={otp}
                onChange={setOTP}
                numInputs={4}
                renderSeparator={<span>-</span>}
                containerStyle={{
                  width: '100%',
                  justifyContent: 'center',
                  margin: '20px 0',
                }}
                renderInput={(props) => (
                  <input
                    {...props}
                    className="border-unihance border !w-[40px] !h-[40px] rounded-md text-unihance"
                  />
                )}
              />
            </div>
            {timeLeft === 0 && showOTP && (
              <p className="mb-4">
                Didn''t Receive OTP?{' '}
                <button
                  className="rounded-lg bg-transparent w-15 h-7 text-unhance underline font-bold p-1"
                  onClick={submitUser}
                >
                  Resend
                </button>
              </p>
            )}
            {timeLeft !== 0 && showOTP && (
              <p className="mb-4">Resend OTP in: {formatTime(timeLeft)}</p>
            )}
          </div>
        )}
        <Button onClick={showOTP ? verifyOTP : submitUser} bold disabled={loading}>
          <div className="flex flex-col justify-center items-center">
            {loading && <Loader />}

            {!loading && (showOTP ? 'Confirm' : 'Continue')}
            {loading && showOTP && (
              <p className="w-full text-sm text-white">
                Please wait while we generate your exam.
              </p>
            )}
          </div>
        </Button>
      </CenterCardContainer>
    </PageCenter>
  )
}

export default QuizTopicsScreen
