import { ReactNode, useEffect, useState } from 'react'
// import { quiz } from '../data/QuizQuestions'
import { QuizContextTypes, Result, ScreenTypes } from '../types'
import { QuizContext, initialState } from './QuizContext'

type QuizProviderProps = {
  children: ReactNode
}

const QuizProvider = ({ children }: QuizProviderProps) => {
  const [examId, setExamId] = useState<number | null>(null)
  const [timer, setTimer] = useState<number>(initialState.timer)
  const [endTime, setEndTime] = useState<number>(initialState.endTime)
  const [quizTopic, setQuizTopic] = useState<string>('React')
  const [quizDetails, setQuizDetails] = useState<any>({
    totalQuestions: 20,
    totalScore: 100,
    totalTime: 15,
    selectedQuizTopic: 'React',
    obtainedScore: 0,
    date: new Date().toISOString(),
    totalQuestionAttempted: 0,
  })
  const [result, setResult] = useState<Result[]>(initialState.result)
  const [answers, setAnswers] = useState<any[]>([])
  const [currentScreen, setCurrentScreen] = useState<ScreenTypes>(
    initialState.currentScreen
  )

  const [questions, setQuestions] = useState<any>([])

  useEffect(() => {
    localStorage.removeItem('U_TOKEN')
  }, [])

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      // This will show a confirmation message (in some browsers)
      event.preventDefault()
      event.returnValue = '' // Setting returnValue is necessary in some browsers
    }

    if (
      currentScreen !== ScreenTypes.SplashScreen &&
      currentScreen !== ScreenTypes.QuizTopicsScreen
    ) {
      window.addEventListener('beforeunload', handleBeforeUnload)
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }

    return () => {
      // Cleanup event listener on component unmount
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [currentScreen])

  // const {
  //   questions: [],
  //   totalQuestions,
  //   totalTime,
  //   totalScore,
  // } = quiz[quizTopic]

  const selectQuizTopic = (topic: string) => {
    setQuizTopic(topic)
  }

  // useEffect(() => {
  //   setTimer(totalTime)
  //   setQuestions(quizQuestions)
  // }, [quizTopic])

  // const quizDetails = {
  //   totalQuestions,
  //   totalScore,
  //   totalTime,
  //   selectedQuizTopic: quizTopic,
  // }

  const quizContextValue: QuizContextTypes = {
    currentScreen,
    setCurrentScreen,
    quizTopic,
    selectQuizTopic,
    questions,
    setQuestions,
    result,
    setResult,
    setAnswers,
    answers,
    quizDetails,
    timer,
    setTimer,
    endTime,
    setEndTime,
    setQuizDetails,
    setExamId,
    examId,
  }

  return <QuizContext.Provider value={quizContextValue}>{children}</QuizContext.Provider>
}

export default QuizProvider
