import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { AppLogo } from '../../config/icons'
import { PageCenter } from '../../styles/Global'

interface LogoAnimationProps {
  logoSize: string
}

const LogoAnimation = styled.div<LogoAnimationProps>`
  svg {
    width: ${({ logoSize }) => logoSize};
    transition: width 1s;
  }
`

const SplashScreen = () => {
  const [logoSize, setLogoSize] = useState('20vw')

  // useEffect(() => {
  //   const handleResize = () => {
  //     if (window.innerWidth < 900) {
  //       setLogoSize('240px')
  //     } else {
  //       setLogoSize('350px')
  //     }
  //   }

  //   // Set initial logo size
  //   handleResize()

  //   // Update logo size on window resize
  //   window.addEventListener('resize', handleResize)

  //   // Clean up event listener on component unmount
  //   return () => {
  //     window.removeEventListener('resize', handleResize)
  //   }
  // }, [])

  return (
    <div className="!bg-unihance">
      <PageCenter justifyCenter bg={'rgb(14, 56, 94)'}>
        <LogoAnimation logoSize={logoSize}>
          {/* <AppLogo /> */}
          <img
            src="/images/u-logo.png"
            alt="Unihance"
            width="20vw"
            height="10vh"
            style={{ transition: 'width 1s', width: '175px', height: '50px' }}
          />
        </LogoAnimation>
      </PageCenter>
    </div>
  )
}

export default SplashScreen
