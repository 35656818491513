import React from 'react'

const Input = ({ value, onChange, label, name, error }: any) => {
  return (
    <div className="relative z-0 w-full mb-6 group">
      <input
        type="text"
        id="floating_input"
        className={`block py-2.5 px-0 w-full text-sm text-white bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-white peer ${
          error ? '!border-red-700' : ''
        }`}
        placeholder=" "
        required
        value={value}
        onChange={onChange}
        name={name}
      />

      <label
        htmlFor={name}
        className={`absolute text-sm text-white duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-white peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 ${
          error ? '!text-red-700' : ''
        }`}
      >
        {label}
        <span className="text-red-700">*</span>
      </label>
      {!!error && <span className="text-red-700 text-xs">{error}</span>}
    </div>
  )
}

export default Input
