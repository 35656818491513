import styled from 'styled-components'

import { AppLogo, StartIcon } from '../../config/icons'
import { useQuiz } from '../../context/QuizContext'
import {
  CenterCardContainer,
  HighlightedText,
  LogoContainer,
  PageCenter,
} from '../../styles/Global'
import { ScreenTypes } from '../../types'
import { convertSeconds } from '../../utils/helpers'

import Button from '../ui/Button'

const AppTitle = styled.h2`
  font-weight: 700;
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.themeColor};
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`

const DetailTextContainer = styled.div`
  font-size: 1.25;
  font-weight: 500;
  margin-top: 15px;
  margin-bottom: 40px;
  text-align: left;
  max-width: 500px;
  @media (max-width: 768px) {
    font-size: 0.75rem;
  }
`

const DetailText = styled.p`
  font-size: 1.25rem;
  font-weight: 500;
  margin-top: 15px;
  line-height: 1.3;
  @media (max-width: 768px) {
    font-size: 0.75rem;
  }
`

const QuizDetailsScreen = () => {
  const { setCurrentScreen, quizDetails } = useQuiz()

  const { selectedQuizTopic, totalQuestions, totalScore, totalTime } = quizDetails

  const goToQuestionScreen = () => {
    setCurrentScreen(ScreenTypes.QuestionScreen)
  }

  return (
    <PageCenter light justifyCenter>
      <CenterCardContainer>
        <LogoContainer>
          {/* <AppLogo /> */}
          <img
            src="/images/u-logo.png"
            alt="Unihance"
            width="350px"
            height="250px"
            style={{ transition: 'width 1s' }}
          />
        </LogoContainer>
        <AppTitle>UNIHANCE Assessment</AppTitle>
        <DetailTextContainer>
          {/* <DetailText>
            Selected Quiz Topic: <HighlightedText>{selectedQuizTopic}</HighlightedText>
          </DetailText> */}
          <DetailText>
            <HighlightedText>Internet Connectivity: </HighlightedText>
            Ensure that you have a stable and reliable internet connection throughout the
            exam. A strong connection is essential to avoid any interruptions during the
            exam.
          </DetailText>
          <DetailText>
            <HighlightedText>Exam Duration: </HighlightedText>
            The exam will last for 15 minutes. Please manage your time accordingly to
            complete as many of the 20 questions as possible within this timeframe.
          </DetailText>
          <DetailText>
            <HighlightedText>Scoring: </HighlightedText>
            The exam is scored out of 100 points, with a passing score of 60 points.
          </DetailText>
          <DetailText>
            <HighlightedText>Interruption Policy: </HighlightedText>
            In case of any exam interruptions, such as a loss of internet connectivity or
            screen refresh, the results will be submitted based on the questions you have
            answered up to the point of interruption.
          </DetailText>

          <DetailText>
            We recommend checking your internet connection before starting the assessment
            to ensure a smooth experience. If you encounter any issues or have questions,
            please reach out to our{' '}
            <a
              href="mailto:support@unihance.com"
              className="font-bold"
              style={{ color: '#f6a509' }}
            >
              support team{' '}
            </a>
            for assistance.
          </DetailText>
        </DetailTextContainer>
        <Button
          text="Start"
          icon={<StartIcon />}
          iconPosition="left"
          onClick={goToQuestionScreen}
          bold
        />
      </CenterCardContainer>
    </PageCenter>
  )
}

export default QuizDetailsScreen
