import { createContext, useContext } from 'react'
import { QuizContextTypes, ScreenTypes } from '../types'

export const initialState: QuizContextTypes = {
  currentScreen: ScreenTypes.SplashScreen,
  setCurrentScreen: () => { },
  quizTopic: 'React',
  selectQuizTopic: () => { },
  questions: [],
  setQuestions: () => { },
  result: [],
  setResult: () => { },
  timer: 900,
  setTimer: () => { },
  endTime: 0,
  setEndTime: () => { },
  quizDetails: {
    totalQuestions: 20,
    totalScore: 100,
    totalTime: 900,
    selectedQuizTopic: 'React',
    obtainedScore: 0,
    date: "string",
    totalQuestionAttempted: 0
  },
  answers: [],
  setAnswers: () => { },
  setQuizDetails: (data) => { },
  setExamId: (data) => { },
  examId: null
}

export const QuizContext = createContext<QuizContextTypes>(initialState)

export function useQuiz() {
  return useContext(QuizContext)
}
