import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'

import { AppLogo, CheckIcon, Next, TimerIcon } from '../../config/icons'
import { useQuiz } from '../../context/QuizContext'
import { useTimer } from '../../hooks'
import { device } from '../../styles/BreakPoints'
import { PageCenter } from '../../styles/Global'
import { ScreenTypes } from '../../types'

import Button from '../ui/Button'
import ModalWrapper from '../ui/ModalWrapper'
import Question from './Question'
import QuizHeader from './QuizHeader'
import axios from 'axios'
import toast from 'react-hot-toast'
import { headers } from '../../utils/helpers'

const QuizContainer = styled.div<{ selectedAnswer: boolean }>`
  width: 900px;
  min-height: 500px;
  background: ${({ theme }) => theme.colors.cardBackground};
  border-radius: 4px;
  padding: 30px 60px 80px 60px;
  margin-bottom: 70px;
  position: relative;
  @media ${device.md} {
    width: 100%;
    padding: 15px 15px 80px 15px;
  }
  button {
    span {
      svg {
        path {
          fill: ${({ selectedAnswer, theme }) =>
            selectedAnswer ? `${theme.colors.buttonText}` : `${theme.colors.darkGray}`};
        }
      }
    }
  }
`

const LogoContainer = styled.div`
  text-align: center;
  margin-bottom: 50px;
  @media ${device.md} {
    margin-bottom: 30px;
  }
  img {
    width: 350px;
    height: 100px;
  }

  @media (max-width: 768px) {
    img {
      width: 150px;
      height: 40px;
    }
  }
  svg {
    width: 220px;
    rect {
      stroke: ${({ theme }) => theme.colors.appLogo};
    }
    path {
      fill: ${({ theme }) => theme.colors.appLogo};
    }
  }
`

const ButtonWrapper = styled.div`
  position: absolute;
  right: 60px;
  bottom: 30px;
  display: flex;
  gap: 20px;
  @media ${device.sm} {
    justify-content: flex-end;
    width: 90%;
    right: 15px;
  }
`

const QuestionScreen: FC = () => {
  const [activeQuestion, setActiveQuestion] = useState<number>(0)
  const [selectedAnswer, setSelectedAnswer] = useState<string[]>([])
  const [selectedAnswerId, setSelectedAnswerId] = useState<string[]>([])
  const [showTimerModal, setShowTimerModal] = useState<boolean>(false)
  const [showResultModal, setShowResultModal] = useState<boolean>(false)
  const [submitting, setSubmitting] = useState<boolean>(false)

  const {
    questions,
    quizDetails,
    result,
    setResult,
    setCurrentScreen,
    timer,
    setTimer,
    setEndTime,
    setAnswers,
    examId,
    setExamId,
  } = useQuiz()

  const currentQuestion = questions[activeQuestion]
  console.log('QuestionScreen', { questions, currentQuestion })
  const { question, type, choices, code, image, correctAnswers, questionId } =
    currentQuestion

  const onClickNext = () => {
    const isMatch: boolean = false
    // selectedAnswer.length === correctAnswers.length &&
    // selectedAnswer.every((answer) => correctAnswers.includes(answer))
    console.log('selectedAnswer', { selectedAnswer })
    // adding selected answer, and if answer matches key to result array with current question
    setResult([
      ...result,
      {
        ...currentQuestion,
        selectedAnswer,
        isMatch,
        questionData: { questionId, answerId: selectedAnswer?.[0] },
      },
    ])
    setAnswers((prev: any) => [...prev, selectedAnswer])
    if (activeQuestion !== questions.length - 1) {
      setActiveQuestion((prev) => prev + 1)
    } else {
      // how long does it take to finish the quiz
      const timeTaken = quizDetails.totalTime - timer
      setEndTime(timeTaken)
      setShowResultModal(true)
    }
    setSelectedAnswer([])

    const answerForm = new FormData()
    answerForm.append('answerId', selectedAnswer?.[0])
    answerForm.append('questionId', questionId!)
    answerForm.append('examId', `${examId}`)
    axios
      .post(`${process.env.REACT_APP_API}/api/Exam/question/answer`, answerForm, {
        headers: {
          ...headers,
          Authorization: `Bearer ${localStorage.getItem('U_TOKEN')}`,
        },
      })
      .then((res) => {})
      .catch((err) => {
        toast.error('An error occurred while trying to submit your answer.')
      })
  }

  const handleAnswerSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target

    if (type === 'MAQs') {
      if (selectedAnswer.includes(name)) {
        setSelectedAnswer((prevSelectedAnswer) =>
          prevSelectedAnswer.filter((element) => element !== name)
        )
      } else {
        setSelectedAnswer((prevSelectedAnswer) => [...prevSelectedAnswer, name])
      }
    }

    if (type === 'MCQs' || type === 'boolean') {
      if (checked) {
        setSelectedAnswer([name])
      }
    }
  }

  const handleModal = () => {
    const formData = new FormData()
    result?.map((r, i) => {
      formData?.append(`data[Questions][${i}][id]`, r.questionData.questionId!)
      formData?.append(`data[Questions][${i}][answer]`, r.questionData.answerId!)
    })
    setSubmitting(true)
    axios
      .post(`${process.env.REACT_APP_API}/api/Exam/submit`, formData, {
        headers: {
          ...headers,
          Authorization: `Bearer ${localStorage.getItem('U_TOKEN')}`,
        },
      })
      .then((res) => {
        setCurrentScreen(ScreenTypes.ResultScreen)
        document.body.style.overflow = 'auto'
        setSubmitting(false)
      })
      .catch((err) => {
        setSubmitting(false)
        toast.error('An error occurred while trying to submit your exam.')
      })
  }

  // to prevent scrolling when modal is opened
  useEffect(() => {
    if (showTimerModal || showResultModal) {
      document.body.style.overflow = 'hidden'
    }
  }, [showTimerModal, showResultModal])

  // timer hooks, handle conditions related to time
  useTimer(timer, quizDetails, setEndTime, setTimer, setShowTimerModal, showResultModal)

  return (
    <PageCenter>
      <QuizContainer selectedAnswer={selectedAnswer.length > 0}>
        <div className="flex items-center justify-center">
          <LogoContainer>
            {/* <AppLogo /> */}
            <img
              src="/images/u-logo.png"
              alt="Unihance"
              width="350px"
              height="250px"
              style={{ transition: 'width 1s' }}
            />
          </LogoContainer>
        </div>
        <QuizHeader
          activeQuestion={activeQuestion}
          totalQuestions={quizDetails.totalQuestions}
          timer={timer}
        />
        <Question
          question={question}
          code={code}
          image={image}
          choices={choices}
          type={type}
          handleAnswerSelection={handleAnswerSelection}
          selectedAnswer={selectedAnswer}
        />
        <ButtonWrapper>
          <Button
            text={activeQuestion === questions.length - 1 ? 'Finish' : 'Next'}
            onClick={onClickNext}
            icon={<Next />}
            iconPosition="right"
            disabled={selectedAnswer.length === 0}
          />
        </ButtonWrapper>
      </QuizContainer>
      {/* timer or finish quiz modal*/}
      {(showTimerModal || showResultModal) && (
        <ModalWrapper
          title={showResultModal ? 'Done!' : 'Your time is up!'}
          subtitle={`You have attempted ${result.length} questions in total.`}
          onClick={handleModal}
          icon={
            showResultModal ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="3.5em"
                height="3.5em"
                viewBox="0 0 24 24"
              >
                <g fill="none" stroke="currentColor" stroke-width="2">
                  <circle cx="12" cy="12" r="9" />
                  <path d="m8 12l3 3l5-6" />
                </g>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2em"
                height="2em"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M12 20a7 7 0 0 1-7-7a7 7 0 0 1 7-7a7 7 0 0 1 7 7a7 7 0 0 1-7 7m7.03-12.61l1.42-1.42c-.45-.51-.9-.97-1.41-1.41L17.62 6c-1.55-1.26-3.5-2-5.62-2a9 9 0 0 0-9 9a9 9 0 0 0 9 9c5 0 9-4.03 9-9c0-2.12-.74-4.07-1.97-5.61M11 14h2V8h-2m4-7H9v2h6z"
                />
              </svg>
            )
          }
          buttonTitle="SUBMIT RESULT"
          loading={submitting}
        />
      )}
    </PageCenter>
  )
}

export default QuestionScreen
